import { render, staticRenderFns } from "./Packages.html?vue&type=template&id=7cf360e6&scoped=true&"
import script from "./Packages.ts?vue&type=script&lang=ts&"
export * from "./Packages.ts?vue&type=script&lang=ts&"
import style0 from "./Packages.css?vue&type=style&index=0&id=7cf360e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf360e6",
  null
  
)

export default component.exports
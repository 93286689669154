import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { Watch } from "vue-property-decorator";
import { IPackage } from "@/models/IPackage";
import PackageServices from "@/services/PackageServices";

@Options({
  components: {
    AdminLayout,
  },
})
export default class Packages extends Vue {
  active = false;
  loading = false;
  isloaded = false;
  userId = 0;
  totalItems = 0;
  current_page = 1;
  search: string = "";
  packages: any = [];
  password: string = "";
  package: IPackage = {
    packageId: 0,
    packageName: "",
    packagePrice: 0,
    isActive: false,
    userId: 0,
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  async created() {
    var result = await PackageServices.getListofPackages();
    this.packages = result;
    this.isloaded = true;

    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.userId = user.userId;
    this.package.userId = this.userId;
  }

  @Watch("packages")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  onBack() {
    this.active = !this.active;
  }

  async onOpen() {
    this.active = !this.active;
  }

  async onEdit(packageId: number) {
    this.packages = await PackageServices.getPackageById(packageId);
    this.active = !this.active;
  }

  onSubmit() {
    (this as any).$Progress.start();
    this.loading = true;
    this.package.userId = this.userId;
    PackageServices.savePackage(this.package).then(
      (data) => {
        this.loading = false;
        (this as any).$vs.notify({
          title: "New Pakcage",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        if (this.package.packageId == 0) {
          this.package = {
            packageId: 0,
            packageName: "",
            packagePrice: 0,
            isActive: false,
            userId: 0,
          };
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New Package",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}

import { IResponse } from "@/models/IClient";
import { IPackage } from "@/models/IPackage";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class PackageService {
  package!: IPackage;
  lstPackage: IPackage[] = [];
  responsedata: any;

  savePackage(model: IPackage): Promise<IResponse> {

    model.userId = userId

    this.responsedata = axios
      .post<IResponse>(API_URL + "Admin/SavePackage", model, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
  getListofPackages(): Result {


    this.responsedata = axios
      .get<IPageResponse>(API_URL + "Admin/GetAllPackage", { headers: auhtHeader() })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getPackageById(packageId: number): Promise<IPackage> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL + "Admin/GetPackageById?packageId=" + packageId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }


}

export default new PackageService();
